import React, { useState, useEffect, useRef } from 'react';
import { Box, Grommet, Text } from 'grommet';

const theme = {
	global: {
		font: {
			family: 'Roboto',
			size: '18px',
			height: '20px'
		}
	}
};

function useInterval(callback, delay) {
	const savedCallback = useRef();

	// Remember the latest callback.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		function tick() {
			savedCallback.current();
		}
		if (delay !== null) {
			let id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
	}, [delay]);
}

function App() {
	const [count, setCount] = useState(0);
	const [currentTime, setCurrentTime] = useState(Date.now());
	const [lastUpdateTime, setUpdateTime] = useState(Date.now());
	const [deltaTime, setDeltaTime] = useState(1);
	useInterval(() => {
		setCurrentTime(Date.now());
		setDeltaTime(currentTime - lastUpdateTime);
		setUpdateTime(currentTime);

		setCount(count + (1 * deltaTime / 1000));
		console.log(count);
		console.log(currentTime, deltaTime, lastUpdateTime);
	}, 1000);
	return (
		<Grommet theme={theme} full>
			<Box direction="row" fill background="dark-2" justify="center" align="center">
				<Box
					direction="row-responsive"
					justify="center"
					align="center"
					pad="medium"
					background="dark-4"
					gap="medium"
					width="medium"
					height="medium"
					onClick={() => setCount(5)}
				>
					<Text>{Math.trunc(count)}</Text>
				</Box>
			</Box>
		</Grommet>
	);
}

export default App;
